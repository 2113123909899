//
// _table.scss
//

.table {
  th {
      font-weight: $font-weight-bold;
  }
}

.table td {
  vertical-align: middle;
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}
