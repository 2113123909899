.order-state-wrapper {
  width: 100%;
  background: #ffffff;
  margin: auto;
  border-radius: 5px;
}

.order-state-wrapper .progress-bar {
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  display: inline-block;
  color: black;
  text-align: center;
  list-style-type: none;
}

.order-state-wrapper .progress-bar li {
  float: left;
  font-size: 16px;
  line-height: 20px;
  width: 25%;
  position: relative;
  z-index: 1;
}
.order-state-wrapper.customer .progress-bar li {
  width: 33.3333%;
}

.order-state-wrapper .progress-bar li:before {
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  display: block;
  font-size: 15px;
  line-height: 36px;
  transition: color 0.25s linear, border-color 0.25s linear;
  color: #d5dbdb;
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto 5px auto;
  border: 2px #d5dbdb solid;
}

.order-state-wrapper:not(.customer) .progress-bar li:hover::before {
  cursor: pointer;
  color: $primary;
  border-color: $primary;
}

.order-state-wrapper .progress-bar li:after {
  content: "";
  width: calc(100% - 20px);
  height: 2px;
  background: linear-gradient(to right, $primary 50%, #d5dbdb 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background 0.25s linear;
  position: absolute;
  margin: auto;
  right: 50%;
  top: 19px;
  z-index: -1;
}

.progress-bar li:first-child:after {
  display: none;
}

.progress-bar li.active:before {
  color: $primary;
  border-color: $primary;
}

.progress-bar li.active:after {
  background-position: left bottom;
}

.overlay:hover {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.25s opacity ease;
  border-radius: 0.2rem !important;
  background: rgba(0, 0, 0, 0.35);
  opacity: 0;

  div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 3.5em;
    color: #fff;
    cursor: pointer;
    text-align: center;
    width: 100%;
  }
}

input.hide-arrows::-webkit-outer-spin-button,
input.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.hide-arrows[type="number"] {
  -moz-appearance: textfield;
}

.search {
  input {
    border: none;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f3f3f3 !important;
    box-shadow: none;
    color: #000;
    border: 1px solid rgba(#fff, 0.07);
    border-radius: 30px;
  }
  .fa {
    position: absolute;
    z-index: 10;
    font-size: 13px;
    line-height: 38px;
    right: 13px;
    top: 0;
    color: #525f80;
  }
}

.dataTable {
  td,
  th {
    vertical-align: middle;
  }
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.button-style {
  /* 
    1rem is the size of the spinner-width-sm in bootstrap 4
    https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss#L1078
  */
  left: calc(50% - calc(1rem / 2));
}

.span-style {
  /* 
    we have to offset the inner child or else 
    the spinner width will offset the center
    of the child text 
  */
  margin-right: 1rem;
}

/* print styles */
@media print {
  .no-print {
    display: none !important;
  }
  .print-input {
    border: none;
    resize: none;
    padding: 0;
  }
}

.card.popup {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0px;
  pointer-events: none;
  z-index: 2000;
  padding: 1rem 1.5rem;
  opacity: 0;
  transition: 0.25s ease;
  gap: 10px;

  .buttons {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  &.show {
    transform: translate(-50%, -30px);
    opacity: 1;
    pointer-events: all;
  }

  .dropup .dropdown-menu {
    transform: translate3d(0px, 0px, 0px) !important;
    border-radius: 0.25rem;
    overflow: hidden;

    .btn {
      width: 100%;
      border-radius: 0;
    }
  }
}
