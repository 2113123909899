.cart-icon {
    i {
        font-size: 28px;
        color: $header-item-color;
    }

    .badge {
        position: absolute;
        top: 17px;
        right: 7px;
    }
}

.cart-item {
    .media {
        padding: 0.75rem 1rem;

        &:hover {
            background-color: $gray-100;
        }
    }
}

.main-content {
  min-height: 100vh;
}

.cart-dropdown {
  width:500px;
}
